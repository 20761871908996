import { render, staticRenderFns } from "./closeFittingList.vue?vue&type=template&id=45d5cdd3&scoped=true"
import script from "./closeFittingList.vue?vue&type=script&lang=js"
export * from "./closeFittingList.vue?vue&type=script&lang=js"
import style0 from "./closeFittingList.vue?vue&type=style&index=0&id=45d5cdd3&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d5cdd3",
  null
  
)

export default component.exports