<template>
  <!-- 贴身毛列表 -->
  <div>
    <div class="notice-cont">
      <div class="notice-list-cont">
        <div class="notice-list-head f-c">
          <div class="f-c" style="margin-left: 55px;">
            <img class="head-img" src="../../assets/img/foot.png" alt="">
            <span class="head-title">贴身毛</span>
          </div>
          <div class="notice-head-line"></div>
        </div>
        <div class="notice-lists">
          <div class="list-conts">
            <div class="conts-img" style="height: 464px;">
              <img style="width: 100%;height: 100%;" src="" alt="">
            </div>
            <div class="conts-txt">
              <p class="txt-p">【委托展示】内容内容内容内容内容内 容内容内容内容内容内容</p>
              <div class="f-c">
                <div class="txt-img"></div>
                <div class="txt-user">用户名</div>
                <div class="txt-time">2024年04月05日</div>
              </div>
            </div>
          </div>
          <div class="list-conts">
            <div class="conts-img" style="height: 714px;">
              <img style="width: 100%;height: 100%;" src="" alt="">
            </div>
            <div class="conts-txt">
              <p class="txt-p">【委托展示】内容内容内容内容内容内 容内容内容内容内容内容</p>
              <div class="f-c">
                <div class="txt-img"></div>
                <div class="txt-user">用户名</div>
                <div class="txt-time">2024年04月05日</div>
              </div>
            </div>
          </div>
          <div class="list-conts">
            <div class="conts-img" style="height: 714px;">
              <img style="width: 100%;height: 100%;" src="" alt="">
            </div>
            <div class="conts-txt">
              <p class="txt-p">【委托展示】内容内容内容内容内容内 容内容内容内容内容内容</p>
              <div class="f-c">
                <div class="txt-img"></div>
                <div class="txt-user">用户名</div>
                <div class="txt-time">2024年04月05日</div>
              </div>
            </div>
          </div>
          <div class="list-conts">
            <div class="conts-img" style="height: 564px;">
              <img style="width: 100%;height: 100%;" src="" alt="">
            </div>
            <div class="conts-txt">
              <p class="txt-p">【委托展示】内容内容内容内容内容内 容内容内容内容内容内容</p>
              <div class="f-c">
                <div class="txt-img"></div>
                <div class="txt-user">用户名</div>
                <div class="txt-time">2024年04月05日</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="go-gujia f-c">
      <span>立刻估价</span>
      <img src="../../assets/img/forward.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: 'noticeList',
  data(){
    return{

    }
  },
  mounted(){
    
  },
  methods:{
    
  }
}
</script>
<style scoped lang="less">
.line{
    width: 2px;
    height: 20px;
    background: #E9B96B;
    margin-left: 20px;
}
.head{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #24242D;
}
.head-img{
    width: 40px;
    height: 40px;
    margin-right: 35px;
}
.head-cont{
    display: flex;
    align-items: center;
}
.head-cont span{
    font-size: 20px;
    color: #636378;
    margin-left: 20px;
    cursor: pointer;
}
.head-cont span:hover{
    color: #B7B7DE;
}
.notice-cont{
  /* display: flex; */
  position: fixed;
  background: url(../../assets/img/bg.jpg)center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.notice-list-cont{
  width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 80px;
}
.notice-list-head{
  position: relative;
  height: 150px;
  border-radius: 25px 25px 0px 0px;
  background: linear-gradient(180deg, rgba(233, 185, 107, 0.1) 0%, rgba(233, 185, 107, 0) 99%), #24242D;
  .head-img{
    width: 50px;
    height: 50px;
    margin-right: 30px;
  }
  .head-title{
    font-size: 30px;
    color: #E9B96B;
  }
}
.notice-head-line{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(270deg, rgba(233, 185, 107, 0) 0%, #E9B96B 51%, rgba(233, 185, 107, 0) 100%);
}
.notice-lists{
  width: 1200px;
  background: #24242D;
  padding: 35px 35px 75px 35px;
  box-sizing: border-box;
  margin-bottom: 170px;
  border-radius: 0 0 25px 25px;
  column-count: 2;
  column-gap: 30px;
  .list-conts{
    width: 550px;
    margin-bottom: 35px;
    .conts-img{
      width: 100%;
      background: #9B9B9B;
      border-radius: 15px 15px 0 0;
    }
    .conts-txt{
      padding: 27px 30px;
      color: #9494A5;
      .txt-img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #9B9B9B;
        margin-right: 5px;
      }
      .txt-user{
        flex: 1;
        font-size: 25px;
      }
      .txt-time{
        font-size: 25px;
      }
    }
  }
}
.f-c{
  display: flex;
  align-items: center;
}
.txt-p{
  font-size: 30px;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 20px;
}
.go-gujia{
  position: fixed;
  right: 405px;
  top: 715px;
  width: 156px;
  height: 50px;
  border-radius: 8px;
  background: #E9B96B;
  padding: 0 15px;
  box-sizing: border-box;
  cursor: pointer;
  img{
    width: 24px;
    margin-right: 10px;
  }
  span{
    color: #101015;
    font-size: 18px;
    margin-right: 20px;
  }
}
</style>